<template>
  <div id="phoneCall-modal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" style="background-color: white;">
              <div class="modal-header  p-0 pt-1">
                <div class="d-flex col-md-11 p-0">
                  <h5 class="mr-5 mt-3 col-md-4 head-width">{{ selectedLead.client_name }} ({{ selectedLead.id }})</h5>
                  <div class="d-flex  form-group col-md-7">
                    <div class="ml-5 mr-2 col-md-6 mt-2 call-drop-width">
                      <select class="form-dropdown mr-2  float-left au-input au-input--full form-control" v-model="to">
                        <option v-for="(contact, index) in contactList" :key="index"
                          :value="contact.prefix + contact.value"> {{
                          contact.prefix }} {{ maskContactValue(contact.value) }} {{ contact.contact_type }}</option>
                      </select>
                    </div>
                    <div class="mt-2 close-btn-width">
                      <!-- <button class="btn btn-success" v-if="rc_widget == true">
                        <a :href="'tel:' + to" class="text-decoration-none  text-white"> Make Call </a>
                      </button> -->
                      <span v-if="rc_widget == true"><a :href="'tel:' + to"
                          class="text-decoration-none  text-white"><button :disabled="isButtonDisabled"
                            class="btn bg-success float-left text-white">Make Call</button></a></span>
                      <span v-else><button class="btn btn-success btn-lg " @click="makePhoneCall"
                          :disabled="isButtonDisabled">Make
                          Call</button></span>
                    </div>
                  </div>
                </div>
                <button type="button" class="close pt-0 col-md-1" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" @click="$emit('close')">&times;</span>
                </button>
              </div>
              <div class="modal-body bodyhei">
                <table class="table table-borderless table-striped table-earning border-bottom">
                  <thead>
                    <tr style="background-color: #553b9b61">
                      <th class="my-element">Type</th>
                      <th class="my-element">To Phone Number</th>
                      <th class="my-element">Time Initiated</th>
                      <th class="my-element">Result</th>
                      <th class="my-element">Duration</th>
                      <th class="my-element text-center">Recording</th>
                    </tr>
                  </thead>
                  <tbody v-if="callRecordList == ''">
                    <tr v-if="showLoader">
                      <td colspan="12" class="text-center">
                        <div class="loadingio-spinner-spinner-hg7ewyizc2s">
                          <div class="ldio-2hsc4oor189">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr class="tr-shadow" v-for="(callLog, index) in callRecordList" :key="index">
                      <td>{{ callLog.direction }}</td>
                      <td>{{ callLog.to }}</td>
                      <td>{{ callLog.time }}</td>
                      <td>{{ callLog.result }}</td>
                      <td>
                        <!-- <p>Duration (Seconds): {{ callLog.duration }}</p> -->
                        {{ convertSecondsToMinutes(callLog.duration) }}
                      </td>
                      <td class="text-center"><i class="fa-regular fa-circle-play fa-lg"></i></td>
                    </tr>
                    <!-- <tr>
                      <td colspan="12">{{ callRecordList }}</td>
                    </tr> -->
                    <tr class="spacer"></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vue from 'vue'
import store from "@/store/index.js";

export default {
  name: 'phoneCall-modal',
  data: () => ({
    selectedLeadNumber: '',
    selectedleadid: [],
    callRecordList: [],
    showLoader: false,
    client_id: '',
    to: '',
    selectedId: 0,
    clientLastname: '',
    clientFirstname: '',
    isButtonDisabled: false,
    rc_widget: localStorage.getItem("rc_widget") === "true" || false,
  }),
  props: {
    selectedLead: Object,
    selectedClient: Object,
  },
  watch: {
    rc_widget(newValue, oldVal) {
      localStorage.setItem("rc_widget", newValue.toString());
    },
  },
  methods: {
    maskContactValue(value) {
      if (value.length < 6) return value;
      const visibleChars = 2;
      const hiddenChars = value.length - visibleChars - 3;
      const maskedValue = value.substring(0, visibleChars) + '*'.repeat(hiddenChars) + value.substring(value.length - 3);
      return maskedValue;
    },
    convertSecondsToMinutes(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} min ${remainingSeconds} sec`;
    },
    // getclientContact() {
    //   const _vm = this;
    //   _vm.axios
    //     .post("/ringout", { to: this.to })
    //     .then(function (response) {
    //     })
    //     .catch(function () { });
    // },
    makePhoneCall() {
      const _vm = this;
      _vm.isButtonDisabled = true;
      store.state.isLoaderShow = true;
      _vm.axios
        .post("/ringout", { to: this.to })
        .then(function (response) {
          store.state.isLoaderShow = false;
          _vm.isButtonDisabled = false;
        })
        .catch(function () {
          store.state.isLoaderShow = false;
          _vm.isButtonDisabled = false;
        });
    },
    getClientCallHistory() {
      const clientId = this.selectedLead.client_id;
      const lead_id = this.selectedLead.id;

      this.axios
        .get(`/lead-call-logs?clientId=${clientId}&leadId=${lead_id}`)
        .then((response) => {
          let encryptedData = response.data.data;
          let decryptedData = atob(encryptedData);
          let data = JSON.parse(decryptedData);
          this.callRecordList = data;
          this.showLoader = false;
        })
        .catch((error) => { });
    },
  },
  computed: {
    contactList() {
      if (
        this.selectedClient &&
        this.selectedClient.client_phones
      ) {
        const phoneList = this.selectedClient.client_phones;
        const preferredContact = phoneList.find(contact => contact.preferred === 1);
        this.to = preferredContact ? preferredContact.prefix + preferredContact.value : "";
        return phoneList;
      }
      return [];
    },

  },
  mounted() {
    const _vm = this;
    _vm.getClientCallHistory();
    // if (this.$route.params.id > 0) {
    // }
  }
}
</script>
<style scoped>
/* .bodyhei{
  min-height: 34em;
  max-height: 34em;
} */
.v-toast--bottom {
  z-index: 9999 !important;
}

div#rc-widget.Adapter_root {
  z-index: 9999 !important;
}

#phoneCall-modal .modal-dialog {
  --bs-modal-width: 90% !important;
}

.modal-body {
  max-height: calc(100vh - 125px) !important;
  min-height: calc(100vh - 125px) !important;
  overflow-x: hidden;
}

@media (max-width: 767px) {


  .head-width {
    width: 250px;
  }

  .call-drop-width {
    width: 200px;
    margin-left: 10px !important;
  }

  .close-btn-width {
    width: 100px;
  }
}
</style>
