<template>
  <div id="email-modal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header p-0">
                <h4 class="modal-title pl-2 pt-2">{{ selectedLead.client_name
                  }} ({{ selectedLead.id }})</h4>
                <button type="button" class="close pr-4" @click="$emit('close')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- {{ emailsList }} -->
                <b-row md="12" class="popup-width " style="">
                  <b-col md="2" class="temp-width" style="height: 100vh;">
                    <iq-card class=" mb-0 bgcolor pad-mar"
                      style="border-radius: 8px; overflow-x: hidden; height: 100%; overflow-y: auto;">
                      <!-- <template v-slot:body> -->
                      <div @click="getEmailHistory()">
                        <div class="align-items-center p-0 w-100" @click="showEmailHistory = true">
                          <span class="badge badge-light w-100"
                            style="padding-top: 15px;height: 45px;font-size: medium;color: black;cursor:pointer;background-color: #ccc1f0 !important;border-radius: 8px 8px 0 0;">
                            Email History
                          </span>
                        </div>
                      </div>
                      <div class="mr-1 ml-1 align-items-center justify-content-between pt-2">
                        <!-- <b-row> -->
                        <div class="nav flex-column nav-pills text-left" id="v-pills-tab" role="tablist"
                          aria-orientation="vertical">
                          <div v-for="(staticTemplates, key) in staticTemplatesList" :key="key">
                            <a class="m-1 pb-3" style="font-weight: 700;" @click="activeTab = key"> {{ key }}</a>
                            <ul :id="key" :class="activeTab === key ? 'show' : ''" class="p-0">
                              <li v-for="(staticData, index) in staticTemplates" :key="key + index"
                                :class="{ active: currentTab == staticData.name }" style="margin-top: -5px;">
                                <a href="javascript:;" @click="changeTab(staticData)"
                                  :class="{ active: currentTab == staticData.name, 'disabled-link': isLinkDisabled(staticData) }"
                                  class="m-1 text-decoration-none small"> {{
                                    staticData.name }}</a>
                                <span class="badge badge-success float-right" style="line-height: 1.2;">
                                  {{ staticData.use_count }}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <!-- </b-row> -->
                      </div>
                      <!-- </template> -->
                    </iq-card>
                  </b-col>
                  <b-col md="10" class="email-content-width" style="height: 100vh;">
                    <iq-card class="pt-3 mb-0 bgcolor pad-mar" style="height: 100%;overflow-x: hidden;">
                      <template v-slot:body>
                        <div class="align-items-center justify-content-between ">
                          <b-row>
                            <div class="col-md-12 p-0 ">
                              <div class="align-items-center p-2 scrollemaildetailstable" v-if="showEmailHistory">
                                <table class="table table-borderless table-striped table-earning ">
                                  <tbody v-if="showLoader" style="height: 100vh;">
                                    <tr>
                                      <td colspan="12" class="text-center">
                                        <div class="loadingio-spinner-spinner-hg7ewyizc2s">
                                          <div class="ldio-2hsc4oor189">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <table class="p-0 w-100">
                                      <tbody v-for="(staticEmail, key) in visibleEmails" :key="key">
                                        <tr v-if="visibleEmails.length == 0">
                                          <td colspan="12" class="text-center">
                                            <img src="../../../../assets/images/user/empty_item.svg" height="200"
                                              width="200" />
                                          </td>
                                        </tr>
                                        <tr @click="toggleRowVisible(staticEmail)" style="cursor:pointer;">
                                          <td class="double-check-width"><i class="fa-solid fa-check-double"
                                              :class="staticEmail.open_count != 0 ? 'text-primary' : 'text-muted'"
                                              @click.stop="openPaymentFormTime(key)"></i> {{
                                                staticEmail.open_count != 0 ? staticEmail.open_count : '' }}</td>
                                          <td class="ellipsis-text">{{ staticEmail.email_subject }}</td>
                                          <td style="width: 15%; " class="ellipsis-text">
                                            {{ staticEmail.template_name }}

                                          </td>
                                          <td style="width: 40%;text-align: right; ">{{
                                            maskEmail(staticEmail.client_email) }}
                                            &nbsp;&nbsp; {{ staticEmail.created_at| formatDateShort }}</td>
                                          <!-- <td style="max-width: 5%;" @click="toggleRowVisible">f</td> -->
                                          <td class="text-center"
                                            style="width: 5%; position: relative;cursor: pointer;">
                                            <i class="fa-solid fa-caret-down fa-lg"></i>
                                          </td>
                                        </tr>
                                        <tr v-if="showRow === staticEmail.id">
                                          <td colspan="12" class="text-center" style="background-color: #fff">
                                            <div v-html="removeEditableAttributes(staticEmail.email_body || '')"
                                              style="max-height: 24em;overflow-x: hidden;">
                                            </div>
                                          </td>
                                        </tr>
                                        <div v-if="paymentFormOpenTime === key && staticEmail.open_time != null"
                                          id="contact-us-comment">
                                          <transition name="modal">
                                            <div class="modal-mask">
                                              <div class="modal modal-wrapper" style="overflow: hidden;">
                                                <div class="modal-dialog "
                                                  style="align-content: space-evenly; text-align: -webkit-center;">
                                                  <div class="modal-content" style="width: 30%;">
                                                    <div class="modal-header">
                                                      <h4 class="modal-title">{{ selectedLead.id }}</h4>
                                                      <button type="button" class="close"
                                                        @click="paymentFormOpenTime = false">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      <div class="form-group" v-if="staticEmail.open_time"
                                                        style="text-align: left;">
                                                        <p v-for="(time, index) in parseOpenTimes(staticEmail.open_time)"
                                                          :key="index">
                                                          Opened: {{ time| formatDateShort }}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </transition>
                                        </div>
                                      </tbody>
                                    </table>
                                  </tbody>
                                </table>

                                <div class="" style="text-align: center;">
                                  <button class="showMorebtn" @click="loadNextBatch"
                                    v-if="emailsToShow < staticEmailHistoryList.length">Show
                                    More <i class="fa-solid fa-angle-down ml-1"></i></button>
                                </div>
                              </div>
                              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(sendEmail)">
                                  <div class="form-group">
                                    <div id="email-compose" class="active show" aria-labelledby="home-tab"
                                      v-if="!showEmailHistory">
                                      <!-- <email-compose :emails="emails" :selectedTemplate="selectedTemplate"  :selectedLead="selectedLead"></email-compose> -->
                                      <div class="form-group row">
                                        <div class="d-flex">
                                          <multiselect class="dropdownselect" :hide-selected="true"
                                            v-model="selectedEmail" style="width:90%" :searchable="false"
                                            :options="maskedEmailsList" :close-on-select="false" :multiple="true"
                                            id="multiple" label="maskedValue" track-by="maskedValue">
                                          </multiselect>
                                          <div class="send-btn" style="width:10%">
                                            <button type="submit" id="send-btn" class="btn btn-primary ml-2"
                                              :disabled="buttonDisabled || (!selectedEmail.length && isSelfChecked == false)"
                                              style="height: 2.8rem;">
                                              <i class="fa fa-paper-plane mr-1" aria-hidden="true"></i>
                                              Send
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- {{ selectedLead }} -->
                                      <div class="form-group row">
                                        <div class="d-flex ">
                                          <validation-provider name="Subject" rules="required" v-slot="{ errors }"
                                            style="width:88%">
                                            <input type="text" id="subject" v-model="emailData.subject"
                                              class="form-control" :class="(errors.length > 0 ? ' is-invalid' : '')"
                                              placeholder="Subject">
                                            <span class="invalid-feedback">{{ errors[0] }}</span>
                                          </validation-provider>
                                          <div class="send-panel text-center mt-2 ml-2" style="width:12%">
                                            <div
                                              class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
                                              <input type="checkbox" class="custom-control-input bg-primary"
                                                id="customCheck-30" v-model="isSelfChecked" />
                                              <label class="custom-control-label mr-3" for="customCheck-30">To
                                                Myself</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <b-form-group>
                                        <div class="border col-md-12 bg-white"
                                          style=" overflow-x: hidden; border-radius: 8px; overflow-y: auto; height: calc(100vh - 165px);"
                                          v-html="renderEmailBody" @input="handleInput" @paste="handlePaste"></div>
                                      </b-form-group>

                                      <!-- <div class="form-group row align-items-center">
                                    <div class="d-flex flex-grow-1 align-items-center justify-content-end">
                                      <div class="send-panel">
                                        <div
                                          class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
                                          <input type="checkbox" class="custom-control-input bg-primary"
                                            id="customCheck-30" v-model="isSelfChecked" />
                                          <label class="custom-control-label mr-3" for="customCheck-30">Send only to
                                            myself</label>
                                        </div>

                                      </div>
                                      <div class="send-btn">
                                        <button type="button" id="send-btn" class="btn btn-primary ml-2"
                                          @click="sendEmail()"
                                          :disabled="!selectedEmail.length && isSelfChecked == false"><i
                                            class="fa fa-paper-plane mr-1" aria-hidden="true"></i>Send</button>
                                      </div>
                                    </div>
                                  </div> -->

                                    </div>
                                  </div>
                                </form>
                              </ValidationObserver>
                            </div>
                          </b-row>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </div>
              <div class="modal-footer p-1 foot">

              </div>
            </div>
          </div>

        </div>
      </div>
    </transition>

  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import vue from 'vue'
import {
  _
} from "vue-underscore"
import Multiselect from 'vue-multiselect';
import { Function } from "core-js";
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: 'This field is required'
});
export default {
  name: 'email-modal',
  data: () => ({
    selectedLeadNumber: '',
    selectedleadid: [],
    currentTab: '',
    staticTemplatesList: [],
    staticTemplates: '',
    activeTab: '',
    showEmailBlock: false,
    selectedTemplate: null,
    selectedId: 0,
    body: '',
    subject: '',
    emailData: {},
    isSelfChecked: false,
    emails: [],
    userEmail1: "",
    userEmail: [],
    clearEmails: false,
    selectedEmail: [],
    editedContent: {},
    emailData: {
      subject: "",
      body: "",
    },
    staticEmailHistoryList: [],
    showEmailHistory: true,
    showRow: false,
    batchSize: 15,
    totalEmails: 0,
    emailsToShow: 15,
    showLoader: false,
    buttonDisabled: false,
    paymentFormOpenTime: false,
    clientLastname: '',
    clientFirstname: ''
  }),
  components: {
    Multiselect,
  },
  props: {
    selectedLead: Object,
    selectedClient: Object,
  },

  computed: {
    visibleEmails() {
      return this.staticEmailHistoryList.slice(0, this.emailsToShow);
    },

    renderEmailBody() {
      return this.emailData.body.replace(
        /class="([^"]*\beditable\b[^"]*)"/g,
        (match, classAttributes) => {
          if (classAttributes.includes('style="')) {
            const updatedStyle = classAttributes.replace(
              /(style="[^"]*)"/,
              `$1 outline: none; box-shadow: none;`
            );
            return `class="${updatedStyle}" contenteditable="true" style="outline: none; box-shadow: none;"`;
          } else {
            return `${match} contenteditable="true" style="outline: none; box-shadow: none;"`;
          }
        }
      );
    },
    emailsList() {
      if (this.selectedClient && this.selectedClient.client_emails && this.selectedClient.client_emails.length > 0) {
        return this.selectedClient.client_emails;
      }
      return [];
    },
    maskedEmailsList() {
      return this.emailsList.map(email => {
        const atIndex = email.value.indexOf('@');
        if (atIndex > 2) {
          const maskedValue = email.value.substring(0, 2) + '*'.repeat(atIndex - 2) + email.value.substring(atIndex - 1);
          return { ...email, maskedValue };
        } else {
          // Handle cases where the email is too short to mask properly
          return { ...email, maskedValue: email.value };
        }
      });
    },

    // maskedEmailsList() {
    //   return this.emailsList.map(email => {
    //     const atIndex = email.value.indexOf('@');
    //     const maskedValue = email.value.substring(0, 2) + '*'.repeat(atIndex - 2) + email.value.substring(atIndex - 1);
    //     return { ...email, maskedValue };
    //   });
    // },
    user() {
      return this.$store.state.userDetail;
    },
  },
  watch: {

    selectedLead: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.id) {
          this.getStaticTemplate();
          this.getEmailHistory();
        }
      },
    },
    selectedTemplate(newValue) {
      this.emailData = newValue;
    },
    clearEmails(val) {
      if (val) {
        this.clearSelectedEmails();
      }
    },
    isSelfChecked(newVal, oldVal) {
      if (newVal === true) {
        const atIndex = this.user.email.indexOf('@');
        const maskedValue = this.user.email.substring(0, 2) + '*'.repeat(atIndex - 2) + this.user.email.substring(atIndex - 1);
        this.selectedEmail.push({
          value: this.user.email,
          maskedValue: maskedValue
        });
      } else {
        const index = this.selectedEmail.findIndex(
          (email) => email.value === this.user.email
        );
        if (index !== -1) {
          this.selectedEmail.splice(index, 1);
        }
      }
    },
    selectedEmail(newVal, oldVal) {
      const userEmailIndex = newVal.findIndex(
        (email) => email.value === this.user.email
      );
      if (userEmailIndex === -1) {
        this.isSelfChecked = false;
      }
    },
  },
  methods: {
    handlePaste(event) {
      event.preventDefault();
      const plainText = (event.clipboardData || window.clipboardData).getData('text');
      document.execCommand('insertText', false, plainText);
    },
    parseOpenTimes(openTimes) {
      if (openTimes) {
        return openTimes.split(',');
      }
      return [];
    },
    openPaymentFormTime(key) {
      let _vm = this;
      _vm.paymentFormOpenTime = _vm.paymentFormOpenTime === key ? null : key;
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, '0');
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    maskEmail(email) {
      const atIndex = email.indexOf("@");
      if (atIndex > 2) {
        const masked =
          email.substring(0, 2) +
          "*".repeat(atIndex - 3) +
          email.charAt(atIndex - 1) +
          email.substring(atIndex);
        return masked;
      } else if (atIndex === 2) {
        return email.substring(0, 2) + "*" + email.substring(atIndex);
      } else if (atIndex === 1) {
        return email.charAt(0) + "*" + email.substring(atIndex);
      } else if (atIndex === 0) {
        return "*" + email.substring(atIndex);
      }
      return email;
    },
    // maskEmail(email) {
    //   const atIndex = email.indexOf('@');
    //   if (atIndex > 1) {
    //     const masked = email.substring(0, 2) + '*'.repeat(atIndex - 3) + email.substring(atIndex - 1, atIndex) + email.substring(atIndex);
    //     return masked;
    //   }
    // },
    isLinkDisabled(staticData) {
      return staticData.use_count !== 0 && staticData.use_type === "Single Use";
    },
    clearSelectedEmails() {
      this.selectedEmail = [];
      // this.clearEmails = false; // uncheck the checkbox
    },
    sendEmail() {
      const _vm = this;
      const selectedEmail1 = [];
      _vm.selectedEmail.forEach((element) => {
        selectedEmail1.push(element.value);
      });

      _vm.buttonDisabled = true;

      _vm.emailData["toEmails"] = selectedEmail1;
      _vm.emailData.lead_id = _vm.selectedLead.id;

      let editedHtml = _vm.renderEmailBody;
      let editedContentExist = false;

      for (const tagName in _vm.editedContent) {
        const editedContent = _vm.editedContent[tagName];
        const tagRegex = new RegExp(
          `<${tagName}[^>]*class="editable"[^>]*>[\\s\\S]*?<\\/${tagName}>`,
          "g"
        );

        if (editedContent.trim() !== "") {
          editedContentExist = true;
          const replacement = `<${tagName} class="editable" contenteditable="true">${editedContent}</${tagName}>`;
          editedHtml = editedHtml.replace(tagRegex, replacement);
        }
      }

      if (editedContentExist) {
        _vm.emailData.body = editedHtml;
      }

      _vm.axios
        .post("/email-followup-templates-email", _vm.emailData)
        .then((response) => {
          _vm.getEmailHistory();
          _vm.showEmailHistory = true;
          _vm.buttonDisabled = false;
          this.isSelfChecked = false;
          // _vm.staticTemplatesList = '';
          _vm.getStaticTemplate();
          _vm.selectedEmail = [];
        })
        .catch((error) => {
        });
    },

    customLabel({ value }) {
      return `${value}`;
    },
    toggleEmailInput() {
      this.isSelfChecked = !this.isSelfChecked;
    },
    disableButton() {
      return !this.selectedEmail.length;
    },
    handleInput(event) {
      const editableElement = event.target.closest(".editable");
      if (editableElement) {
        const tagName = editableElement.tagName.toLowerCase();
        this.editedContent[tagName] = editableElement.innerHTML;
      }
    },
    clearForm() {
      this.selectedEmail = [];
      this.emailData.subject = "";
      this.emailData.body = "";
      this.isSelfChecked = false;
    },
    handleScroll() {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.clientHeight;

      if (scrollY + windowHeight >= bodyHeight - 200) {
        this.loadNextBatch();
      }
    },
    loadNextBatch() {
      this.emailsToShow += this.batchSize;
    },
    updateShowEmailHistory(newValue) {
      this.showEmailHistory = newValue;
    },
    // toggleRowVisible(index) {
    //   if (this.showRow === index) {
    //     this.showRow = null;
    //   } else {
    //     this.showRow = index;
    //   }
    // },

    toggleRowVisible(staticEmail) {
      let emailData = this.staticEmailHistoryList.find(email => email.id === staticEmail.id);

      if (!emailData.email_body) {
        this.axios.get(`/get-email-body/${staticEmail.id}/${staticEmail.template_uuid}`)
          .then(response => {
            let data = JSON.parse(atob(response.data.data));
            emailData.email_body = data;
            staticEmail.email_body = data;
            this.showRow = this.showRow === staticEmail.id ? null : staticEmail.id;
          })
          .catch(error => {
          });
      } else {
        staticEmail.email_body = emailData.email_body;
        this.showRow = this.showRow === staticEmail.id ? null : staticEmail.id;
      }
    },
    changeTab(data) {
      //     if (data != 'email-compose'){
      this.showEmailHistory = false;
      this.currentTab = data.name;
      this.selectedTemplate = data;
      // if (!this.originalBody) {
      //   // Store the original template body content if it's not already stored
      //   this.originalBody = this.emailData.body;
      // }
      // this.emailData.body = this.originalBody;
      //     }else{
      //         this.currentTab = data;
      //         this.selectedTemplate = {};
      //     }
    },

    getStaticTemplate() {
      const _vm = this;

      const leadId = _vm.selectedLead.id;
      this.axios
        .get("/email-followup-templates-groups/" + leadId)
        .then(function (response) {
          _vm.staticTemplatesList = response.data.data;
        })
        .catch(function () { });
    },
    getEmailHistory() {
      const _vm = this;
      _vm.showLoader = true;
      const leadId = _vm.selectedLead.id;
      const apiEndpoint = "/email-followup-email-history/" + leadId;
      this.axios
        .get(apiEndpoint, _vm.staticEmailHistoryList)
        .then(function (response) {
          let encryptedData = response.data.data;
          let decryptedData = atob(encryptedData);
          let data = JSON.parse(decryptedData);
          _vm.staticEmailHistoryList = data;
          _vm.showLoader = false;
          _vm.showRow = false;
        })
        .catch(function () { });
    },
    removeEditableAttributes(html) {
      return html.replace(/contenteditable="true"/g, "");
    },
  },
  created() {
    // this.originalBody = this.emailData.body;
    this.originalBody = '';
    this.clearForm();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    $("#dropdownselect").on(
      "multiselect:opening multiselect:closing",
      function (event) {
        var $searchfield = $(this).parent().find(".multiselect-search__field");
        $searchfield.prop("disabled", true);
      }
    );
    const agreeCheckbox = document.getElementById("customCheck-30");
    const sendButton = document.getElementById("send-btn");

    agreeCheckbox.addEventListener("change", function () {
      if (this.checked) {
        sendButton.removeAttribute("disabled");
      } else {
        sendButton.setAttribute("disabled", true);
      }
    });
    // window.addEventListener("scroll", this.handleScroll);
  }
}
</script>

<style scoped>
.modal-mask1 {
  position: fixed;
  z-index: 1999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper1 {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

/* .bodyhei {
    min-height: 34em;
    max-height: 34em;
} */

.bgcolor {
  background-color: #e7e7e766;
}

.foot {
  border-top: none !important;
  background-color: white !important;
}

div#rc-widget.Adapter_root {
  z-index: 9999 !important;
}

#email-modal .modal-dialog {
  --bs-modal-width: 90% !important;
  height: 100vh;
}

#email-modal .modal-dialog1 {
  --bs-modal-width: 90% !important;
  height: 100vh;
}

.editable[contenteditable="true"]:focus {
  outline: none;
}

.stamp {
  position: absolute;
  bottom: -10px;
  right: -5px;
  /* background-color: #ff0000; */
  color: #000;
  padding: 5px 5px;
  /* border-radius: 3px; */
}

.showMorebtn {
  border: none;
  border-radius: 8px;
  padding: 8px;
  color: #fff;
  background-color: #4e1ff96e;
}

.disabled-link {
  pointer-events: none;
  color: gray;
  text-decoration: none;
}

@media (max-width: 767px) {
  .pad-mar {
    padding: 0;
    margin: 0;
  }

  .popup-width {
    width: 680px;
  }

  .temp-width {
    width: 180px;
  }

  .double-check-width {
    width: 150px;
  }

  .email-content-width {
    width: 500px;
  }

  .scrollemaildetailstable {
    /* width: 600px; */
    overflow-y: hidden;
  }

  /* .iq-card {
    width: 700px
  } */

  /* .modal-dialog {
    width: 1370px;
  } */
}
</style>
