import { render, staticRenderFns } from "./SMS-modal.vue?vue&type=template&id=115ace98&scoped=true"
import script from "./SMS-modal.vue?vue&type=script&lang=js"
export * from "./SMS-modal.vue?vue&type=script&lang=js"
import style0 from "./SMS-modal.vue?vue&type=style&index=0&id=115ace98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "115ace98",
  null
  
)

export default component.exports